<template>
  <section>
      <span class="d-block f16 text-center">{{exercise.title}}</span>

      <span class="f13 grey--text text--darken-1 d-flex flex-row align-items-center justify-content-center mt-2" v-if="exerciseProp.createDate">
        {{ $t('Date of prescription:') }} 
        <i class="far fa-calendar-alt ms-2 me-1"/>
        {{ exerciseProp.createDate | moment("dddd , jD jMMMM jYYYY") }} 
        <i class="far fa-clock ms-2 me-1"/>
        {{ exerciseProp.createDate | moment("HH:MM") }} 
      </span>

      <v-card class="d-flex align-items-center my-2" flat v-if="showDoctorInfo">
        <v-avatar class="ms-0 me-2 my-2 rounded-lg" size="96">
          <v-img :lazy-src="require('@/assets/images/profile-circle.png')" :src="
            doctor.pic != null && doctor.pic != ''
              ? doctor.pic
              : require('@/assets/images/profile.jpg')
          "></v-img>
        </v-avatar>
        <div class="ms-2 me-0 d-flex flex-column">
          <span class="f14 font-weight-bold">
            {{ doctor.firstname + " " + doctor.lastname }}
          </span>

          <span class="mt-1 f13 primary--text text--darken-1">
            {{ doctor.clinic.name }}
          </span>

          <span class="mt-1 f13 grey--text text--darken-1">
            {{ doctor.specialties[0].value }}
          </span>
        </div>
      </v-card>

            
      <div class="grey lighten-4 rounded-lg text-dark px-4 py-2">
        <span class="f15 fw-bold d-block">{{$t('Description')}}</span>
        <p class="f14 pa-2 m-0" v-html="exercise.description"></p>
      </div>

    <v-footer
        app
        paddless
        color="white"
        class="pb-2 pt-0"
      >
      <v-container fluid>
        <div>
          <v-btn color="primary" large block class="rounded-lg" @click="handleNextPage(exerciseProp)">
            {{$t('Start')}}
          </v-btn>
        </div>
      </v-container>
    </v-footer>
  </section>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });


export default {
  data() {
    return {
      currencyMask,

      exercise: {},
      exerciseProp: {},
      doctor: {
        clinic: {},
        specialties: [ 
          {
            value: '',
          }
        ]
      },
      showDoctorInfo: false,
    }
  },
  methods: {
    getExerciseDetails(code) {
      apiService.getExerciseDetails(code)
      .then((response) => {
        this.exercise = response.data;

        if (this.exerciseProp.drMobile) {
          this.showDoctorInfo = true;
          this.getDoctorInfoByMobile(this.exerciseProp.drMobile);
        }
        else {
          this.showDoctorInfo = false;
        }
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    getDoctorInfoByMobile(drMobile) {
      apiService.getDoctorInfoByMobile(drMobile)
      .then((response) => {
        this.doctor = response.data;
      })
      .catch((err) => {
        console.log(err);
        this.$router.go(-1);
      })
    },
    handleNextPage(exercise){
      this.$router.push({name: 'ExerciseDetailsTodo', params: {obj: {...exercise}, id: exercise.eid}})
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);

    if (this.$route.params.obj) {
      this.exerciseProp = this.$route.params.obj;
    }
    else{
      this.$router.go(-1);
    }
    this.getExerciseDetails(this.$route.params.id);
  },
}
</script>
